import axios from 'axios';
import Utils from './Utils';

export default {
    getCdnUrl() {
        return "https://dhwk6gixvyfl3.cloudfront.net/";
    },
    getBaseUrl(type) {
        return "";
        const prodMode = true;
        if(prodMode) {
            if(type=="scrut") {
                return "https://scrutineer.wandernetworks.com";
            } else {
                return "https://afterparty.ai";
            }
        } else {
            return "http://dan.soindrop.com";
        }

    },
    getFullUrl(url) {
        return this.getBaseUrl() + url;
    },

    getPostPutJson(method, title, url, modalData, callback, silent) {
      //window.$("#txtError").hide();
      if(!silent) {
          console.log(method + '-- ' + title + ' data', modalData);
      }
      const baseUrl = this.getBaseUrl();
      const fullUrl = baseUrl + url;
      let ajaxHeader = {
        type: method,
        url: fullUrl,
        startReqTime: new Date().getTime(),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json'
      };

      if(method == "POST_MULTI_PART") {
          ajaxHeader["type"] = "POST";
          ajaxHeader["contentType"] = "multipart/form-data",
          delete ajaxHeader["dataType"];
      }

      if (method == 'POST' || method == 'PUT') {
        ajaxHeader['data'] = JSON.stringify(modalData);
      }
      if(method == 'POST') {
          axios.post(fullUrl, modalData)
              .then(res => {
                  if(callback) {
                      var responseTime = new Date().getTime() - ajaxHeader.startReqTime;
                      //console.log("responseTime", responseTime);
                      return callback(res.data, false, responseTime);
                  }
              })
              .catch(error => {
                  var responseTime = new Date().getTime() - this.startReqTime;
                  console.log("axios POST error", fullUrl, error);
                  if(callback) {
                      return callback(null, error, responseTime);
                  }
              });

      } else if(method == 'PUT') {

          axios.put(fullUrl, modalData)
              .then(res => {
                //console.log('RETURNED', res, res.data);
                  if(callback) {
                      return callback(res.data);
                  }
              })
              .catch(error => {
                console.log("axios PUT error", fullUrl, error);
                  if(callback) {
                      return callback(null, error);
                  }
              });
      } else {
          axios.get(fullUrl, {})
              .then(res => {
                //console.log('RETURNED', res, res.data);
                  if(callback) {
                      //console.log("CALLBACK", callback, res.data);
                      return callback(res.data);
                  }
              })
              .catch(error => {
                console.log("axios GET error", fullUrl, error);
                  if(callback) {
                      return callback(null, error);
                  }
              });
      }
      /*
      window.$.ajax(ajaxHeader).done(function(o) {
        if (callback) {
          return callback(o);
        }
      });
      */
    },
    // TODO: deprecated -- test 2 and replace in future
    // getReqParams(alwaysQuestionMark, extraParams) {
    //     var url = "";
    //     var parts = window.location.href.split("?");
    //
    //     if(alwaysQuestionMark || parts.length > 1) {
    //         url += "?"
    //         if(parts.length > 1) {
    //             url += parts[1];
    //         }
    //     }
    //     if(extraParams) {
    //         if(url.length == 0) {
    //             url += "?";
    //         }
    //         for(var key in extraParams) {
    //             url += "&" + key + '=' + extraParams[key];
    //         }
    //     }
    //
    //     return url;
    // },

    getReqParams(alwaysQuestionMark, extraParams) {
        var parts = window.location.href.split("?")
        if(parts.length > 1) {
            alwaysQuestionMark = true;
        }
        var reqParamStr = parts[1];

        var reqParamParts = [];
        if(reqParamStr && reqParamStr.length) {
            reqParamParts = reqParamStr.split("&");
        }

        var reqParams = {};
        for(var key in reqParamParts) {
            var keyValParts =  reqParamParts[key].split('=');
            var key = keyValParts[0].trim();
            if(keyValParts.length === 2 && key.length > 0) {
                reqParams[key] = keyValParts[1];
            }
        }

        reqParams = $.extend(reqParams, extraParams);

        var out = '';
        for(var key in reqParams) {
            if(out.length != 0) {
                out += '&';
            }
            out += key + '=' + reqParams[key];
        }

        if(alwaysQuestionMark) {
            out = "?" + out;
        }

        return out;
    },

    noErrors(o, hideErrorDisplay) {
        const errors = Utils.get(o, "errors", [{message: "Invalid server response"}]);
        if(errors && errors.length>0) {
            if(!hideErrorDisplay) {
                let errorStr = "Error: \n";
                for(var idx in errors) {
                    //errorStr += "<div>" + errors[idx].message + "</div>"
                    errorStr += errors[idx].message + "\n"
                }
                alert(errorStr);
            }
            return false;
        } else {
            return true;
        }
    },

    postCustomerSwipeStream(modalData, callback) {
      var url = "/api/v1/customerswipestream" + this.getReqParams();
      return this.getPostPutJson("POST", "SwipeStream", url, modalData, callback);
    },

    putCustomer(id, modalData, callback) {
      var url = "/api/v1/customer/" + id + this.getReqParams();
      return this.getPostPutJson("PUT", "putCustomer", url, modalData, callback);
    },

    apiLoadProfile(callback, portal) {
        const baseUrl = this.getBaseUrl();

        let url = baseUrl+"/api/v1/profile";
        let isPortal = false;
        if(portal) {
            //const portal = Utils.get(this.props, "match.params.0");
            console.log("PORTAL", portal);
            url = "/api/v1/profile/"+portal;
            isPortal = true;
        }

        axios.get(url, {})
          .then(res => {
            //console.log('RETURNED', res, res.data);
              if(callback) {
                  return callback(res.data);
              }
          })
          .catch(error => {
            console.log("axios PROFILE error", error);
              if(callback) {
                  return callback(null, error);
              }
          });
      },

    apiLoadMusic(callback, portal) {
        let url = "/api/v1/music";
        return this.getPostPutJson("GET", "Music:", url, null, callback);
    },
    apiLoadArtists(artistName, callback) {
        var url = '/api/v1/music' + "?artist=" + artistName;
        return this.getPostPutJson('GET', 'MusicArtistSearch', url, undefined, callback);
    },
    apiLoadArtist(artistId, callback) {
        var url = '/api/v1/music' + "?artistId=" + artistId;
        return this.getPostPutJson('GET', 'MusicArtistSearch', url, undefined, callback);
    },

    postListing(modalData, callback) {
      var url = "/api/v1/listing" + this.getReqParams();
      return this.getPostPutJson("POST", "Listing", url, modalData, callback);
    },
    putListing(id, modalData, callback) {
      var url = "/api/v1/listing/" + id + this.getReqParams();
      return this.getPostPutJson("PUT", "Listing", url, modalData, callback);
    },

    putMusicListingBuy(id, modalData, callback) {
      var url = "/api/v1/musiclisting/" + id + "/buy" + this.getReqParams();
      return this.getPostPutJson("PUT", "MusicListing", url, modalData, callback);
    },

    putMusicWallet(modalData, callback) {
      var url = "/api/v1/profile" + this.getReqParams();
      return this.getPostPutJson("PUT", "MusicWallet", url, modalData, callback);
    },
    putProfile(modalData, callback) {
      var url = "/api/v1/profile" + this.getReqParams();
      return this.getPostPutJson("PUT", "UpdateProfile", url, modalData, callback);
    },
    postProfileImage(modalData, callback) {
      var url = "/api/v1/afterpartyuploader" + this.getReqParams();
      return this.getPostPutJson("POST", "afterpartyuploader", url, modalData, callback);
    },
    postUploadSellerAsset(modalData, callback) {
      var url = "/api/v1/asset/seller" + this.getReqParams();
      return this.getPostPutJson("POST", "SellerAsset", url, modalData, callback);
    },
    getUpload(id, callback) {
      var url = "/api/v1/upload/" + id + this.getReqParams();
      return this.getPostPutJson("GET", "Upload", url, {}, callback);
    },
    putUpload(id, modalData, callback) {
      var url = "/api/v1/upload/" + id + this.getReqParams();
      return this.getPostPutJson("PUT", "Upload", url, modalData, callback);
    },
    postUpload(modalData, callback) {
      var url = "/api/v1/upload" + this.getReqParams();
      return this.getPostPutJson("POST", "Upload", url, modalData, callback);
    },
    getListingByName(name, callback) {
      if(name.indexOf('?') != -1) {
          console.log("SPLIT");
          name = name.split("?")[0];
      }
      if(name.indexOf('#') != -1) {
          console.log("SPLIT");
          name = name.split("#")[0];
      }
      var url = "/api/v1/listing?name=" + name;
      return this.getPostPutJson("GET", "getListingByName", url, {}, callback);
    },
    getSoinListing(id, callback) {
      var url = "/api/v1/soinlisting/" + id;
      return this.getPostPutJson("GET", "getSoinListing", url, {}, callback);
    },
    getSearch(search, callback) {
      var url = "/api/v1/listing?search=" + search;
      return this.getPostPutJson("GET", "Search", url, {}, callback);
    },
    postConfirmEmailPhone(modalData, callback) {
        var url = "/api/v1/confirmEmailPhone";
        return this.getPostPutJson("POST", "Register", url, modalData, callback);
    },
    postVerifyCodeVerification(modalData, callback) {
        var url = "/api/v1/confirm_verification_code";
        return this.getPostPutJson("POST", "Register", url, modalData, callback);
    },
    postRegister(modalData, callback) {
      var url = "/register?format=json";
      return this.getPostPutJson("POST", "Register", url, modalData, callback);
    },
    postRegisterInApp(modalData, callback) {
      var url = "/register_in_app?format=json";
      return this.getPostPutJson("POST", "RegisterInApp", url, modalData, callback);
    },
    postRegisterGuardian(modalData, callback) {
      var url = "/register/guardian?format=json";
      return this.getPostPutJson("POST", "RegisterGuardian", url, modalData, callback);
    },
    postRegisterWebAppRegister(modalData, callback) {
      var url = "/register/webappregister?format=json";
      return this.getPostPutJson("POST", "RegisterWebAppRegister", url, modalData, callback);
    },
    putRegister(modalData, callback) {
      var url = "/register?format=json";
      return this.getPostPutJson("PUT", "Register", url, modalData, callback);
    },
    postLogin(modalData, callback) {
      var url = "/login?format=json";
      return this.getPostPutJson("POST", "Login", url, modalData, callback);
    },
    getLogout(callback) {
      var url = "/api/v1/logout?format=json";
      return this.getPostPutJson("GET", "Logout", url, {}, callback);
    },
    getSeller(callback) {
      var url = "/api/v1/seller";
      return this.getPostPutJson("GET", "Seller", url, {}, callback);
    },
    getAfterPartyEvent(id, callback) {
      var url = "/api/v1/afterpartyevent/" + id + this.getReqParams();
      return this.getPostPutJson("GET", "getAfterPartyEvent", url, {}, callback);
    },
    postAfterPartyEvent(modalData, callback) {
      var url = "/api/v1/afterpartyevent" + this.getReqParams();
      return this.getPostPutJson("POST", "AfterPartyEvent", url, modalData, callback);
    },
    getAfterPartyEvents(callback) {
      var url = "/api/v1/afterpartyevent" + this.getReqParams();
      return this.getPostPutJson("GET", "getAfterPartyEvents", url, {}, callback);
    },
    getAfterPartyRoom(id, callback) {
      let browserId = Utils.getBrowserId();
      // var url = "/api/v1/afterpartyroom/" + id + this.getReqParams(true) + "&bid=" + browserId;
      var url = "/api/v1/afterpartyroom/" + id + this.getReqParams(true);
      return this.getPostPutJson("GET", "getAfterPartyRoom", url, {}, callback);
    },
    getAfterPartyRoomVips(id, callback) {
      let browserId = Utils.getBrowserId();
      // var url = "/api/v1/afterpartyroom/" + id + this.getReqParams(true) + "&bid=" + browserId;
      var url = "/api/v1/afterpartyroom/" + id + "/vip" + this.getReqParams(true);
      return this.getPostPutJson("GET", "getAfterPartyRoomVips", url, {}, callback);
    },
    getRoomChatLimiter(roomId, callback) {
        var now = new Date().getTime();
        if(window.postRoomChatLoading && (now - window.postRoomChatLoading) < 20000) {
            //console.log("Already loading", );
            return;
        } else {
            //console.log("Loading", );
        }
        window.postRoomChatLoading = new Date().getTime();
        var url = "/api/v1/afterpartychat/"+roomId+"?a="+Date.now();
        return this.getPostPutJson("GET", "RoomChat", url, {}, callback, true);
    },
    getAiChatLimiter(roomId, callback) {
        var now = new Date().getTime();
        if(window.postRoomChatLoading && (now - window.postRoomChatLoading) < 20000) {
            //console.log("Already loading", );
            return;
        } else {
            //console.log("Loading", );
        }
        window.postRoomChatLoading = new Date().getTime();
        var url = "/api/v1/ai/afterpartychat/"+roomId+"?a="+Date.now();
        return this.getPostPutJson("GET", "AI RoomChat", url, {}, callback, true);
    },
    postRoomChat(modalData, callback) {
        // NOTE: this is for adding new messages, use the getRoomChatLimiter()
        // for refreshing messages
        var url = "/api/v1/afterpartychat2";
        var hideLogToConsole = modalData['body'] ? false : true;
        return this.getPostPutJson("POST", "RoomChat", url, modalData, callback, hideLogToConsole);
    },
    postAiChat(modalData, callback) {
        var url = "/api/v1/afterpartyaichat";
        var hideLogToConsole = modalData['body'] ? false : true;
        return this.getPostPutJson("POST", "AiChat", url, modalData, callback, hideLogToConsole);
    },
    getCmsChat(callback) {
      // NOTE: this allows for this URL: afterparty.com/p/example_url/artist_id-135
      // it does not need this.state and it does not need "?" in the URL, but will still work if "?" exist in URL regardless
      const artistId = Utils.getRequest('artist_id', undefined, false, true);
      var url = `/cgp/api/v1/conversation/cmschat`+ this.getReqParams(true, {artist_id: artistId});

      return this.getPostPutJson("GET", "TempChat", url, {}, callback);
    },
    postCmsChat(modalData, callback) {
      const artistId = Utils.getRequest('artist_id', undefined, false, true);
      var url = `/cgp/api/v1/conversation/cmschat`+ this.getReqParams(true, {artist_id: artistId});

      var hideLogToConsole = modalData['body'] ? false : true;
      return this.getPostPutJson("POST", "TempChat", url, modalData, callback, hideLogToConsole);
    },
    postConversationAiChat(modalData, callback) { // NOTE: start new conversation
        // NOTE: this is for adding new messages, use the getAiChatLimiter()
        // for refreshing messages
        var url = "/api/v1/ai/room";
        // var hideLogToConsole = modalData['body'] ? false : true;
        return this.getPostPutJson("POST", "Room Ai Chat", url, modalData, callback);
    },
    getAiConversationsAvailableToUser(callback, artistId) {
        let url = "/api/v1/ai/afterpartyconversations";
        if(artistId) {
            url += `?artist_id=${artistId}`;
        } else {
            url += this.getReqParams();
        }
        return this.getPostPutJson("GET", "Ai Conversations Available to User", url, null, callback);
    },
    getIntroAiUserAudioChip(artistId, callback) { // NOTE: intro audio user chip
        let url = "/api/v1/afterpartyai/audiouserchip/" + artistId;
        return this.getPostPutJson("GET", "Ai Intro User Audio Chip", url, null, callback);
    },
    getPublicProfile(id, callback) {
      var url = "/api/v1/afterpartyprofile/" + id + this.getReqParams();
      return this.getPostPutJson("GET", "getAfterPartyProfile", url, {}, callback);
    },
    postRsvp(modalData, callback) {
      var url = "/api/v1/afterpartyrsvp" + this.getReqParams();
      return this.getPostPutJson("POST", "Rsvp", url, modalData, callback);
    },
    postCustomerEvent(modalData, callback) {
        if(!modalData) {
            modalData = {};
        }
        modalData['browser_id'] = Utils.getBrowserId(true);
        var url = "/api/v1/afterpartycustomerevent" + this.getReqParams();
        return this.getPostPutJson("POST", "CustomerEvent", url, modalData, callback);
    },
    getAfterPartyBlogs(callback) {
      var url = "/api/v1/afterpartyblog" + this.getReqParams();
      return this.getPostPutJson("GET", "getAfterPartyBlogs", url, {}, callback);
    },
    getAfterPartyDoc(id, callback) {
      var url = "/api/v1/afterpartydoc/" + id + this.getReqParams();
      return this.getPostPutJson("GET", "getAfterPartyDoc", url, {}, callback);
    },
    putRoomChatSignal(id, modalData, callback) {
        if(!modalData) {
            modalData = {};
        }
        modalData['browser_id'] = Utils.getBrowserId(true);
        var url = "/api/v1/afterpartyroomchatsignal/"+id;
        return this.getPostPutJson("PUT", "RoomChatSignal", url, modalData, callback);
    },
    postFollow(modalData, callback) {
      var url = "/api/v1/customer_follow";
      return this.getPostPutJson("POST", "Follow", url, modalData, callback);
    },
    postUpgradeVipChat(modalData, callback) {
      var url = "/api/v1/afterpartyupgradechat";
      return this.getPostPutJson("POST", "postUpgradeVipChat", url, modalData, callback);
    },
    postAnalytics(modalData, callback) {
      var url = "/api/v1/afterparty_analytics";
      return this.getPostPutJson("POST", "Analytics", url, modalData, callback);
    },
    postDesoTx(modalData, callback) {
      var url = "/api/v1/desotx";
      return this.getPostPutJson("POST", "Deso Tx", url, modalData, callback);
    },
    postDesoCommit(modalData, callback) {
      var url = "/api/v1/desocommit";
      return this.getPostPutJson("POST", "Deso Commit", url, modalData, callback);
    },
    postDesoBalance(modalData, callback) {
      var url = "/api/v1/desobalance";
      var hideLogToConsole = true;
      return this.getPostPutJson("POST", "Deso Balance", url, modalData, callback, hideLogToConsole);
    },
    postVerifyCode(modalData, callback) {
      var url = "/api/v1/verifycode" + this.getReqParams(true);
      return this.getPostPutJson("POST", "getVerifyCode", url, modalData, callback);
    },
    getMintingStatus(mintCrc, callback) {
        var url = "/api/v1/minting_progress/" + mintCrc;
        return this.getPostPutJson("POST", "getMintingStatus", url, {}, callback);
    },
    resendVerifyCode(modalData, callback) {
      var url = "/api/v1/resendverifycode";
      return this.getPostPutJson("POST", "resendVerifyCode", url, modalData, callback);
    },
    getStripeIntent(num, callback, collectibleId, amount, paymentType, roomId) {
      var url = "/api/v1/stripechargeintent" + this.getReqParams(true) + "&num=" + num;
      if(collectibleId) {
          url += "&collectible=" + collectibleId;
      }
      if(amount) {
          url += "&amount=" + amount;
      }
      if(paymentType) {
          url += "&paymentType=" + paymentType;
      }
      if(roomId) {
          url += "&roomId=" + roomId;
      }
      return this.getPostPutJson("GET", "getStripeIntent", url, {}, callback);
    },
    postStripeIntent(data, callback) {
      var url = "/api/v1/stripe_charge_intent";
      return this.getPostPutJson("POST", "postStripeIntent", url, data, callback);
    },
    postStripeSetupIntent(data, callback) {
      var url = "/api/v1/stripe_setup_intent";
      return this.getPostPutJson("POST", "postStripeSetupIntent", url, data, callback);
    },
    getStripePaymentMethods(callback) {
      var url = "/api/v1/stripe_payment_methods" + this.getReqParams();
      return this.getPostPutJson("GET", "getStripePaymentMethods", url, {}, callback);
    },
    postStripeConfirmPayment(data, callback) {
      var url = "/api/v1/stripe_confirm_payment";
      return this.getPostPutJson("POST", "postStripeConfirmPayment", url, data, callback);
    },
    postStripeDeletePaymentMethod(data, callback) {
      var url = "/api/v1/stripe_delete_payment_method";
      return this.getPostPutJson("POST", "postStripeDeletePaymentMethod", url, data, callback);
    },
    storeStripeTransaction(data,  callback) {
      var url = "/api/stripe/store-transaction";
      return this.getPostPutJson("POST", "storeStripeTransaction", url, data, callback);
    },
    sendPaymentConfirmation(num, amount, callback) {
      var url = "/api/v1/sendpaymentconfirmation" + this.getReqParams(true) + "&num=" + num + "&amount=" + amount;
      return this.getPostPutJson("GET", "sendPaymentConfirmation", url, {}, callback);
    },

    getWalletPermission(collectionId, walletId, callback) {
      var url = "/api/v1/wallet_permission/" + collectionId + "/" + walletId + this.getReqParams(true) + "&format=json";
      return this.getPostPutJson("GET", "WalletPermission", url, {}, callback, true);
    },
    putWalletPermission(collectionId, walletId, modalData, callback) {
      var url = "/api/v1/wallet_permission/" + collectionId + "/" + walletId + this.getReqParams(true) + "&format=json";
      return this.getPostPutJson("PUT", "WalletPermission", url, modalData, callback);
    },
    postPasswordReset(modalData, callback) {
      var url = "/api/v1/afterpartypasswordreset";
      return this.getPostPutJson("POST", "PasswordReset", url, modalData, callback);
    },

    getNfts(crc, callback, extraParams) {
      extraParams['format'] = 'json';
      var url = '/api/v1/nft/' + crc + this.getReqParams(false, extraParams);
      return this.getPostPutJson('GET', 'Nfts', url, undefined, callback);
    },


    postCustomerTicket(modalData, callback) {
      var url = "/api/v1/customer_ticket" + this.getReqParams();
      return this.getPostPutJson("POST", "CustomerTicket", url, modalData, callback);
    },

    putCustomerTicket(id, modalData, callback) {
      var url = "/api/v1/customer_ticket/" + id + this.getReqParams();
      return this.getPostPutJson("PUT", "CustomerTicket", url, modalData, callback);
    },

    // // TODO: change this to getDesoUrl()
    getBitcloutUrl() {
        // return "https://identity.bitclout.com"
        return "https://identity.deso.org"
    },
    getBitcloutTitle() { // TODO: getDesoTitle()
        // return "BitClout"
        return "DeSo"
    },
  ///BID API
  getListingDetail(id, callback) {
    var url = "/api/v1/listing/" + id;
    return this.getPostPutJson("GET", "getListingDetail", url, {}, callback);
  },

  postListingBid(modalData, callback) {
    var url = "/chorus/api/v1/listingbid";
    return this.getPostPutJson("POST", "Post Bid", url, modalData, callback);
  },

  getAfterpartyListing(id, callback) {
    var url = "/api/v1/afterparty-listing/" + id;
    return this.getPostPutJson("GET", "getAfterpartyListing", url, {}, callback);
  },

  postTransaction(modalData, callback) {
    var url = "/api/v1/transaction";
    return this.getPostPutJson("POST", "Transaction", url, modalData, callback);
  },

  postCreateCustodialWallet(modalData, callback) {
    var url = "/api/v1/create_custodial_wallet";
    return this.getPostPutJson("POST", "postCreateCustodialWallet", url, modalData, callback);
  },

  postCreateGuardian(collectionId, tokenId, modalData, callback) {
    var url = "/api/v1/moonpay/lite/deliver_nft/"+collectionId+"/"+tokenId;
    return this.getPostPutJson("POST", "postCreateGuardian", url, modalData, callback);
  },
    getDrops(callback) {
        let url = "/api/v1/drops";
        return this.getPostPutJson("GET", "Drops", url, null, callback);
    },
    getCollectionDrops(collectionId, callback) {
        let url = "/api/v1/drops?all=1&collection="+collectionId;
        return this.getPostPutJson("GET", "Collection Drops", url, null, callback);
    },
    getPost(id, callback, version, extraParams) {
        let url = "/chorus/api/v1/post/"+id + this.getReqParams(true, extraParams);

        if(version) {
            url += "?v="+version;
        }
        return this.getPostPutJson("GET", "Post", url, null, callback);
    },
    getAiPost(id, callback, version, extraParams) {
        let url = "/api/v1/afterpartypost/faq?artist_id="+id; // + this.getReqParams(true, extraParams);

        // if(version) {
        //     url += "?v="+version;
        // }
        return this.getPostPutJson("GET", "Post For Ai Page", url, null, callback);
    },
    getShortLinkPost(id, callback, extraParams) {
        let url = "/api/v1/afterpartypost/"+id + this.getReqParams(true, extraParams);
        return this.getPostPutJson("GET", "AP Post", url, null, callback);
    },
    getMoonpayComplete(contractAddress, tokenId, walletId, callback) {
        let url = "/api/v1/moonpay/completed_tx/"+contractAddress+"/"+tokenId+"/"+walletId;
        return this.getPostPutJson("GET", "MoonpayComplete", url, null, callback);
    },
    getMintingStatus(crc, callback) {
        let url = "/api/v1/minting_progress/"+crc;
        return this.getPostPutJson("GET", "Minting Progress", url, null, callback);
    },
    getCollection(crc, callback) {
        let url = "/api/v1/afterpartycollection/"+crc;
        return this.getPostPutJson("GET", "Afterparty Collection", url, null, callback);
    },
    postFormDataRow(modalData, callback) {
        var url = "/api/v1/afterpartyformdata";
        return this.getPostPutJson("POST", "AP Form Data", url, modalData, callback);
    },
    postFormUrl(url, modalData, callback) {
        return this.getPostPutJson("POST", "AP Form Url", url, modalData, callback);
    },
    postGenerateImages(modalData, callback) {
        var url = "/api/v1/genimages";
        return this.getPostPutJson("POST", "GenerateImages", url, modalData, callback);
    },
    getPhoneValidation(phone, callback) {
        let url = "/api/v1/testphone?phone=" + phone;
        return this.getPostPutJson("GET", "Telephone Validation", url, null, callback);
    },
    pushLogsToServer() {
        if(window.logs) {
            const data = {
                event_type: 9, // javascript_debug_log
                json: JSON.stringify(window.logs),
            }
            this.postCustomerEvent(data, (o) => { console.log("Joined"); });
        }
    },
    postGetRegisterToken(callback) {
        // Check if we already have the tokens.
        var keyRegisterToken = Utils.getCookie("kb");
        var keyEncryptionHash = Utils.getCookie("ks");
        if(keyEncryptionHash && keyRegisterToken) {
            if(callback) {
                return callback();
            }
            return;
        }
        console.log("Tokens BEFORE", keyEncryptionHash, keyRegisterToken);
        var expireMinutes = 2;
        var url = "/api/v1/afterpartytoken";
        var bid = Utils.getBrowserId();
        var modalData = {id:bid};
        var myCallback = function(o) {
            keyRegisterToken = Utils.get(o, "data.keyb");
            keyEncryptionHash = Utils.get(o, "data.keys");
            Utils.setCookie("kb", keyRegisterToken, false, expireMinutes);
            Utils.setCookie("ks", keyEncryptionHash, false, expireMinutes);
            console.log("token", keyRegisterToken, keyEncryptionHash);
            if(callback) {
                return callback();
            }
            return;
        };
        return this.getPostPutJson("POST", "TOKEN", url, modalData, myCallback);
    },
    // NOTE: Only works for ADMINS
    deleteRoom(roomId, callback) {
        let url = "/chorus/api/v1/room/"+roomId+"?delete=17";
        return this.getPostPutJson("GET", "DELETE Room", url, null, callback);
    },
    postAiFeedback(modalData, callback) {
        var url = "/api/v1/ai/feedback";
        return this.getPostPutJson("POST", "AI Feedback", url, modalData, callback);
    },
    getLottie(lottieFile, callback) {
        var url = `/images/lottie/${lottieFile}`;
        return this.getPostPutJson("GET", "Get Lottie", "/images/lottie/ai_landing_pink_1_desktop.json", null, callback);
    },
    getShoutoutListing(shoutoutCrc, callback) {
        var url = `/api/v1/afterparty_shoutout_listing/${shoutoutCrc}`;
        return this.getPostPutJson("GET", "Get ShoutoutListing", url, null, callback);
    },
    postGoogleRegisterLogin(modalData, callback) {
        var url = "/registerlogin/google";
        return this.getPostPutJson("POST", "GoogleRegisterLogin", url, modalData, callback);
    },
    getNextConvo(convoId, callback) {
        var url = `/cgp/api/v1/conversation/next?id=${convoId}`;
        return this.getPostPutJson("GET", "Get Next convo", url, {}, callback);
    },
}
