import React from 'react';

import Utils from '../modules/Utils';
import ll from '../modules/ll';
import Api from '../modules/Api';
import Styles from '../modules/Styles';
import Colors from '../modules/Colors';
import StyleUtils from '../modules/StyleUtils';

import moment, { max } from 'moment'

import Button from '../elements/Button';
import Image from '../elements/Image';
import Icon from '../elements/Icon';
import Modal from '../elements/Modal';
import Input from '../elements/Input';
import Checkbox from '../elements/Checkbox';
import ErrorBox from '../elements/ErrorBox';
import BloatedCircleDefs from '../elements/BloatedCircleDefs';
import Span from '../elements/Span';

import Div from '../components/Div';

import twoAfterpartyLogo from "../assets/images/splash/logo@3x.png"

export default class Doc extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }

    renderPP() {
        const pr = this.props;
        const sty = this.styles;

        return (
            <Div>
                { pr.body ?
                    <Div style={sty.blogBody} dangerouslySetInnerHTML={{ __html: pr.body }} />
                    :
                    null
                }
            </Div>
        );
    }


    render() {
        const pr = this.props;
        const sty = this.styles;

        const twoAfterpartyLogoStyle = StyleUtils.getMediaStyle('twoAfterpartyLogoStyle', sty.twoAfterpartyLogoStyle, sty, StyleUtils.getWidthType(pr.screen.width, 'md'));

        return (
            <Div className="docElement" style={{...sty.docElement, ...(pr.hideLogo ? sty.docReset : null)}}>
                {pr.hideLogo ?
                    null
                    :
                    <Image
                        className="twoAfterpartyLogoStyle"
                        src={twoAfterpartyLogo}
                        style={sty.twoAfterpartyLogoStyle}
                    />
                }

                {this.renderPP()}
            </Div>
        );
    }

    styles = {
        docElement: {
            padding: 40,
            paddingTop: 80,
            backgroundColor: Colors.indigo,
            color: "white",
        },
        docReset: {
            padding: 0,
            paddingTop: 0,
            backgroundColor: "none",
        },
        twoAfterpartyLogoStyle: {
            width: 254,
            marginTop: 40,
            marginBottom: 30,
        },
        twoAfterpartyLogoStyle_md: {
            width: 180,
        },
    };
}
